import React, { useState, useEffect, useRef } from 'react';
import css from './LandingPage.module.css';

function AutoPlaySlideshow(props) {
  const { gifImages, user } = props;
  const delay = 2500;
  const [index, setIndex] = useState(0);
  const [fade, setFade] = useState(false);
  const timeoutRef = useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(() => {
      setFade(!fade);
      setIndex(prevIndex => (prevIndex === gifImages.length - 1 ? 0 : prevIndex + 1));
    }, delay);

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <div
      className={fade ? css.spotlightListingWrapper : css.spotlightListingWrapper2}
      style={{
        backgroundImage: `url(${gifImages[index]})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
      }}
    >
      <div className={css.authorTag}>
        {user.profileImage?.attributes && (
          <img className={css.authorPic} src={user.profileImage.attributes.variants.default.url} />
        )}
        @{user.attributes.profile.displayName?.replace(' ', '-')}
      </div>
    </div>
  );
}

export default AutoPlaySlideshow;
