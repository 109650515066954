import React, { useState, useEffect, useRef } from 'react';
import css from './SectionStories.module.css';
import Stories from 'react-insta-stories';
import defaultProfile from './default-profile.png';
import CloseIcon from '@mui/icons-material/Close';
import { getStories } from './utils';

import dynamic from 'next/dynamic';
const Swiper =
  typeof window !== 'undefined'
    ? require('swiper/react').Swiper
    : dynamic(() => import('swiper/react'), {
        ssr: false,
      });
const SwiperSlide =
  typeof window !== 'undefined'
    ? require('swiper/react').SwiperSlide
    : dynamic(() => import('swiper/react'), {
        ssr: false,
      });

const SectionStories = props => {
  const [stories, setStories] = useState([]);
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
  const [showStories, setShowStories] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      require('swiper/css');
      require('swiper/css/bundle');
      require('swiper/css/navigation');
    }

    getStories()
      .then(resp => {
        setStories(resp);
      })
      .catch(e => {
        console.log(e);
        setStories([]);
      });
  }, []);

  const mobileView = typeof window !== 'undefined' && window.innerWidth < 482;
  const screenWidth = typeof window !== 'undefined' && window.innerWidth;
  let slidesToShow = 6;

  switch (true) {
    case screenWidth && screenWidth < 768:
      slidesToShow = 4;
      break;
    case screenWidth && screenWidth < 1200:
      slidesToShow = 6;
      break;
    case screenWidth && screenWidth > 1200:
      slidesToShow = 8;
      break;
  }

  if (stories.length < 8) {
    return null;
  }

  return (
    <div className={css.storiesWrapper}>
      <Swiper slidesPerView={slidesToShow} onSwiper={swiper => {}} onSlideChange={() => {}}>
        {stories.map(s => {
          return (
            <SwiperSlide>
              <div
                className={css.slideWrapper}
                onClick={() => {
                  const foundIndex = stories.findIndex(st => st.userId === s.userId);
                  setShowStories(true);
                  setCurrentStoryIndex(foundIndex);
                }}
              >
                <div className={css.storyIconWrapper}>
                  <img src={s?.profileImage ?? defaultProfile} className={css.storyUserImage} />
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>

      {showStories && (
        <div className={css.storiesSlidesWrapper}>
          <div className={css.closeIconWrapper} onClick={() => setShowStories(false)}>
            <CloseIcon style={{ color: 'white' }} />
          </div>
          <div
            className={css.storiesWrapperBackgroundColor}
            onClick={() => setShowStories(false)}
          ></div>
          {stories && stories.length > 0 ? (
            <Stories
              stories={stories}
              defaultInterval={5500}
              width={mobileView ? '100vw' : 432}
              height={'100%'}
              currentIndex={currentStoryIndex}
              onAllStoriesEnd={() => {
                setShowStories(false);
              }}
              keyboardNavigation={true}
              storyStyles={{
                background: '#FAF5EF',
                width: '100%',
              }}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};

export default SectionStories;
