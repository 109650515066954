import React, { useState, useEffect } from 'react';
import css from './LandingPage.module.css';
import config from '../../config';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { util as sdkUtil } from '../../util/sdkLoader';
import './SectionCarousel.css';
import { pushToPath } from '../../util/urlHelpers';

import './swiper.css';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { FormattedMessage } from '../../util/reactIntl';
import { Button, NamedLink } from '../../components';
import dynamic from 'next/dynamic';

// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Navigation } from 'swiper';
const Swiper =
  typeof window !== 'undefined'
    ? require('swiper/react').Swiper
    : dynamic(() => import('swiper/react'), {
        ssr: false,
      });
const SwiperSlide =
  typeof window !== 'undefined'
    ? require('swiper/react').SwiperSlide
    : dynamic(() => import('swiper/react'), {
        ssr: false,
      });
const Navigation =
  typeof window !== 'undefined'
    ? require('swiper').Navigation
    : dynamic(() => import('swiper'), {
        ssr: false,
      });
const isDev = process.env.REACT_APP_ENV === 'development';
const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

function WeLoveSection(props) {
  const { intl } = props;
  const [spotLightListings, setSpotlightListings] = useState([]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      require('swiper/css');
      require('swiper/css/bundle');
      require('swiper/css/navigation');
    }

    const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
    const aspectRatio = aspectHeight / aspectWidth;
    const weLoveFilter = isDev ? { stockMode:'match-undefined', minStock: 1} : { pub_weLove: 'true', stockMode:'match-undefined', minStock:1 };
    sdk.listings
      .query({
        ...weLoveFilter,
        include: ['author', 'images', 'author.profileImage', 'profileImage'],
        'fields.listing': ['title', 'geolocation', 'price', 'publicData', 'description'],
        'fields.user': [
          'profile.displayName',
          'profile.abbreviatedName',
          'profile.publicData',
          'profileImage',
          'profile.profileImage',
        ],
        'fields.image': ['variants.portrait-crop', 'variants.portrait-crop2x'],
        'imageVariant.portrait-crop': sdkUtil.objectQueryString({
          w: 400,
          h: 600,
          fit: 'crop',
        }),
        'imageVariant.portrait-crop2x': sdkUtil.objectQueryString({
          w: 800,
          h: 1200,
          fit: 'crop',
        }),
        ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
        ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      })
      .then(res => {
        const listings = res.data.data;
        const images =
          res.data.included &&
          res.data.included.filter(i => {
            return i.type === 'image';
          });
        const users =
          res.data.included &&
          res.data.included.filter(i => {
            return i.type === 'user';
          });

        if (users && users.length > 0) {
          users.forEach(u => {
            const imageId = u.relationships.profileImage.data?.id.uuid;

            const luckyImage = images.find(i => {
              return i.id.uuid === imageId;
            });

            u.profileImage = luckyImage;
          });
        }

        if (listings && listings.length > 0) {
          listings.forEach(l => {
            const imageId = l.relationships.images.data[0]?.id.uuid;
            const authorId = l.relationships.author.data?.id.uuid;

            const luckyImage = images.find(i => {
              return i?.id.uuid === imageId;
            });

            const author = users.find(u => {
              return u?.id.uuid === authorId;
            });
            l.author = author;
            l.images = [luckyImage];
          });
        }

        const listingsLeftToPush = 12 - listings.length;
        let finalListings = [...listings];

        for (let i = 1; i <= listingsLeftToPush; i++) {
          finalListings.push(listings[getRandomInt(listings.length - 1)]);
        }

        const finalListingsFiltered = finalListings.filter(l => l && l.attributes);
        console.log(finalListingsFiltered);

        // filter for sold/no stock. if closed is already not shown
        const finalListingsFiltered1 = finalListingsFiltered.filter(l => {
          const noStock = l?.currentStock?.attributes?.quantity === 0;
          return !noStock;
        });

        setSpotlightListings(finalListingsFiltered1);
      })
      .catch(e => console.log(e));
  }, []);

  const screenWidth = typeof window !== 'undefined' && window.innerWidth;
  const slidesToShow = screenWidth && screenWidth < 768 ? 2.3 : 6;

  if (typeof window === 'undefined') {
    return null;
  }
  return spotLightListings && spotLightListings.length > 0 ? (
    <div className={css.sectionContent}>
      <h2 className={css.sectionTitle}>WE LOVE section</h2>

      <Swiper
        modules={[Navigation]}
        navigation={{
          nextEl: '.custom_next',
          prevEl: '.custom_prev',
        }}
        spaceBetween={10}
        slidesPerView={slidesToShow}
        onSwiper={swiper => {}}
        onSlideChange={() => {}}
      >
        <div className="custom_next">
          {' '}
          {/* we need to add our own arrows, SwiperJs doesn't show default ones  */}
          <ArrowForwardIosIcon style={{ fontSize: '40px' }} />
        </div>
        <div className="custom_prev">
          <ArrowBackIosNewIcon style={{ fontSize: '40px' }} />
        </div>
        {spotLightListings.map(l => {
          const title = l?.attributes?.title;
          const price = l?.attributes?.price;
          const shortTitle = title && (title.length > 15 ? title.slice(0, 12) + '...' : title);
          const selectedMainPicUrl = false; //TODO photo feature l?.attributes?.publicData?.selectedMainPic?.url;
          const mainPicUrl =
            selectedMainPicUrl || l.images[0].attributes.variants['portrait-crop2x'].url;
          return (
            <SwiperSlide>
              <div
                className={css.weLoveItemWrapper}
                onClick={() => {
                  pushToPath(`/l/${l.attributes.title.replace(' ', '-')}/${l.id.uuid}`);
                }}
              >
                <img src={mainPicUrl} className={css.weLoveItemImage} />
                <div className={css.weLoveItemText}>
                  <div className={css.title}>{shortTitle}</div>
                  <div className={css.priceValue}>{price?.amount / 100}лв</div>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>

      <div className={css.buttonWrapper}>
        <NamedLink
          className={css.seeMoreButton}
          name={'SearchPage'}
          to={{ search: 'pub_weLove=true' }}
        >
          <FormattedMessage id="WeLoveSection.seeMore" />
        </NamedLink>
      </div>
    </div>
  ) : null;
}

export default WeLoveSection;
