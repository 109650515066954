import { util as sdkUtil } from '../../../util/sdkLoader';
import { types as sdkTypes } from '../../../util/sdkLoader';
import { pushToPath } from '../../../util/urlHelpers';

const { UUID } = sdkTypes;

const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

const predefinedStoryUsersIds = [
  '62f100ea-3ee4-4d52-b20e-eb04729a30ca',
  '62da5302-c9e0-4685-a40d-e07a278127a9',
  '62d91a32-1fa1-40e8-bf96-945a62e1ada9',
  '62dba641-3b24-4327-a648-e36f28b19e69',
  '62d91a8f-f4e5-42a8-be28-32bdbc25544f',
  '62d91ffe-8124-48df-9ffc-83495e2cbcd4',
  '62d827bd-f52a-41db-b5e9-e710b7fbfc11',
  '62da527e-6bd1-450c-9f01-038b0baefefc',
];

export const getUserFullInfo = userId => {
  const customImagesVariants = {
    'imageVariant.portrait-crop': sdkUtil.objectQueryString({
      w: 400,
      h: 600,
      fit: 'scale',
    }),
    'imageVariant.portrait-crop2x': sdkUtil.objectQueryString({
      w: 800,
      h: 1200,
      fit: 'scale',
    }),
  };

  return sdk.users
    .show({
      id: new UUID(userId),
      include: ['profileImage'],
      expand: true,
    })
    .then(resp => {
      // res.data contains the response data
      let userData = resp.data.data;
      const profileImage = resp.data?.included && resp.data.included[0];
      userData.profileImage = profileImage;

      return sdk.listings
        .query({
          authorId: new UUID(userId),
          include: ['images'],
          'fields.image': ['variants.portrait-crop', 'variants.portrait-crop2x'],
          ...customImagesVariants,
        })
        .then(res => {
          const userListings = res.data.data;
          const images = res.data?.included;

          const listingsWithImages =
            userListings &&
            userListings
              .filter(l => {
                return l.attributes.state === 'published';
              })
              .map(l => {
                let finalListing = { ...l };
                const foundImagesArray = [];
                const listingImagesIds = l.relationships.images.data;

                images.forEach(i => {
                  const foundId = listingImagesIds.find(x => {
                    return x.id.uuid === i.id.uuid;
                  });
                  const isAlreadyPushed = foundImagesArray.find(y => {
                    return y.id.uuid === i.id.uuid;
                  });

                  if (foundId && !isAlreadyPushed) {
                    foundImagesArray.push(i);
                  }
                });

                finalListing.images = foundImagesArray;

                return finalListing;
              });

          userData.listings = listingsWithImages || [];
          return userData;
        })
        .catch(e => console.log(e));
    })
    .catch(e => console.log(e));
};

export const getStories = () => {
  return sdk.currentUser
    .show()
    .then(res => {
      const followingArray = res.data.data.attributes.profile.publicData.following ?? [];

      //add predefined story users if the current user doesn't follow anyone
      predefinedStoryUsersIds.forEach(u => {
        if (!followingArray.includes(u) && followingArray.length < 9) {
          followingArray.push(u);
        }
      });

      const followingUsersPromises = followingArray.map(u => {
        return getUserFullInfo(u).catch(e => console.log(e));
      });

      return Promise.all(followingUsersPromises)
        .then(resp => {
          const following = resp;
          if (following && following.length > 0) {
            const storiesArray = following
              .filter(f => f.listings && f.listings.length > 0)
              .map(x => {
                const storyImage =
                  x.listings[0].images[0].attributes.variants['portrait-crop2x'].url;
                const profileImage = x.profileImage?.attributes?.variants?.default?.url;
                const userDisplayName =
                  x.attributes.profile.displayName.length > 10
                    ? x.attributes.profile.displayName.slice(0, 7) + '...'
                    : x.attributes.profile.displayName;
                const finalStoryObject = {
                  imageUrl: storyImage,
                  dateAdded: new Date().getTime(),
                  profileImage: profileImage,
                  userName: userDisplayName,
                  userId: x.id.uuid,
                  url: storyImage,
                  header: {
                    heading: `@${userDisplayName}`,
                    profileImage: profileImage,
                  },
                  seeMore: () => pushToPath(`/u/${x.id.uuid}`),
                };
                return finalStoryObject;
              });

            return storiesArray;
          }
        })
        .catch(e => {
          console.log(e);
          return [];
        });
    })
    .catch(e => {
      console.log(e);
      return [];
    });
};
