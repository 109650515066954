import React, { useEffect } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import { NamedLink } from '../../../components';

import css from './SectionHero.module.css';

const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

const SectionHero = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  useEffect(() => {
    sdk.currentUser
      .show()
      .then(resp => {
        if (resp.data.data) {
          sdk.currentUser
            .updateProfile({
              publicData: {
                lastActive: new Date().getTime(),
              },
            })
            .catch(e => console.log(e));
        }
      })
      .catch(e => console.log(e));
  }, []);

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        {/* <h1 className={css.heroMainTitle}>
          <FormattedMessage id="SectionHero.title" />
        </h1>
        <h2 className={css.heroSubTitle}>
          <FormattedMessage id="SectionHero.subTitle" />
        </h2> */}
        <a
          href={'https://thenold.com/shop?availability=all&category=&condition=sample-sale&department=&preloved=false&priceRange=0,10000&shipsTo=uk_eu&sortBy=newest&text='}
          className={css.heroButton1}
        >
          SHOP SAMPLES
        </a>

        <NamedLink name="SearchPage" className={css.heroButton2}>
          <FormattedMessage id="SectionHero.browseButton" />
        </NamedLink>
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
