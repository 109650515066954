import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import config from '../../config';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { Logo } from '../../components';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import { NamedLink } from '../../components';
import facebookImage from '../../assets/sneakertimeFacebook-1200x630.jpg';
import twitterImage from '../../assets/sneakertimeTwitter-600x314.jpg';
import { Grid } from '@mui/material';
import SectionHero from './SectionHero/SectionHero';
import SectionHowItWorks from './SectionHowItWorks/SectionHowItWorks';
import SectionFilteredSearches from './SectionFilteredSearches/SectionFilteredSearches';
import SectionWardrobeSpotlight from './SectionWardrobeSpotlight';
import WeLoveSection from './WeLoveSection';
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import ReplyIcon from '@mui/icons-material/Reply';
import gif1 from '../ListingPage/gif1.gif';
import gif2 from '../ListingPage/gif2.gif';
import gif3 from '../ListingPage/gif3.gif';
import gif4 from '../ListingPage/gif4.gif';
import SectionStories from './SectionStories/SectionStories';
import { types as sdkTypes } from '../../util/sdkLoader';
import photoIcon from './photoIcon.svg';
import piggiBank from './piggiBank.svg';
import shareIcon from './shareIcon.png';
import css from './LandingPage.module.css';

const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});
const { UUID } = sdkTypes;
const dataStorageUserId = process.env.REACT_APP_MARKETPLACE_DATA_STORAGE_USER_ID;

export const LandingPageComponent = props => {
  const { history, intl, location, scrollingDisabled } = props;
  const [marketplaceSavings, setMarketplaceSavings] = useState({
    co2Saved: 0,
    treesSaved: 0,
    litersSaved: 0,
    peopleSaved: 0,
    wasteSaved: 0,
  });

  useEffect(() => {
    if (dataStorageUserId) {
      sdk.users.show({ id: new UUID(dataStorageUserId) }).then(res => {
        const userPublicData = res.data.data.attributes.profile.publicData;
        const co2Saved = userPublicData.co2Saved ?? 0;
        const treesSaved = userPublicData.treesSaved ?? 0;
        const litersSaved = userPublicData.litersSaved ?? 0;
        const peopleSaved = userPublicData.peopleSaved ?? 0;
        const wasteSaved = userPublicData.wasteSaved ?? 0;
        setMarketplaceSavings({
          co2Saved,
          treesSaved,
          litersSaved,
          peopleSaved,
          wasteSaved,
        });
      });
    }
  }, []);

  //
  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="LandingPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.categoriesWrapper}>
            <NamedLink
              name="SearchPage"
              to={{ search: 'pub_keyCategory=has_any%3Awoman' }}
              className={css.category}
            >
              {intl.formatMessage({ id: 'LandingPage.woman' })}
            </NamedLink>

            <NamedLink
              name="SearchPage"
              to={{ search: 'pub_keyCategory=has_any%3Aman' }}
              className={css.category}
            >
              {intl.formatMessage({ id: 'LandingPage.men' })}
            </NamedLink>

            <NamedLink
              name="SearchPage"
              to={{ search: 'pub_keyCategory=has_any%3Akids' }}
              className={css.category}
            >
              {intl.formatMessage({ id: 'LandingPage.kids' })}
            </NamedLink>

            <NamedLink
              name="SearchPage"
              to={{ search: 'pub_keyCategory=has_any%3Ababy' }}
              className={css.category}
            >
              {intl.formatMessage({ id: 'LandingPage.baby' })}
            </NamedLink>

            <NamedLink
              name="SearchPage"
              to={{ search: 'pub_keyCategory=has_any%3Abeauty' }}
              className={css.category}
            >
              {intl.formatMessage({ id: 'LandingPage.beauty' })}
            </NamedLink>

            <NamedLink
              name="SearchPage"
              to={{ search: 'pub_keyCategory=has_any%3Ahome' }}
              className={css.category}
            >
              {intl.formatMessage({ id: 'LandingPage.home' })}
            </NamedLink>

            <NamedLink
              name="SearchPage"
              to={{ search: 'pub_weLove=true' }}
              className={css.category}
            >
              {intl.formatMessage({ id: 'LandingPage.weLove' })}
            </NamedLink>

            <NamedLink name="SearchPage" to={{ search: '' }} className={css.category}>
              {intl.formatMessage({ id: 'LandingPage.brands' })}
            </NamedLink>
          </div>
          <div className={css.storiesSectionWrapper}>
            <SectionStories history={history} />
          </div>
          <div className={css.heroContainer}>
            <SectionHero
              rootClassName={css.heroRoot}
              className={css.hero}
              history={history}
              location={location}
            />
            {/* <div className={css.heroSectionLeft}>
              <a
                href={'https://nold.bg/u/63468042-5831-4b6f-b065-2261acd0e47a'}
                className={css.heroButton}
              >
                NOLD by Хриси
              </a>
            </div>
            <div className={css.heroSectionRight}>
              <a href={'https://nold.bg/s?pub_weLove=true'} className={css.heroButton}>
                WE LOVE находки
              </a>
            </div> */}
          </div>

          <div className={css.heroContainer}>
            {/* <SectionHero
              rootClassName={css.heroRoot}
              className={css.hero}
              history={history}
              location={location}
            /> */}
            <div className={css.heroSectionLeft}>
              <a
                href={'https://nold.bg/u/62f100ea-3ee4-4d52-b20e-eb04729a30ca'}
                className={css.heroButton}
              >
                NOLD by NOLD
              </a>
            </div>
            <div className={css.heroSectionRight}>
              <a href={'https://nold.bg/s?pub_weLove=true'} className={css.heroButton}>
                WE LOVE находки
              </a>
            </div>
          </div>

          {/* <div className={css.heroContainer}>
       
            <div className={css.heroSectionLeft}>
              <a
                href={'https://noldbyyou.com/u/62f100ea-3ee4-4d52-b20e-eb04729a30ca'}
                className={css.heroButton}
              >
                NOLD by NOLD
              </a>
            </div>
            <div className={css.heroSectionRight}>
              <a href={'https://nold.bg/s?pub_weLove=true'} className={css.heroButton}>
                WE LOVE находки
              </a>
            </div>
          </div> */}
          <ul className={css.sections}>
            <li className={css.section}>
              <div className={css.sectionContent}>
                <Grid container item xs={12}>
                  <Grid container item xs={12} md={3}>
                    <Grid container item xs={12}>
                      <div className={css.howToSellText}>
                        {intl.formatMessage({ id: 'LandingPage.howToSellOn' })}
                        <br /> {intl.formatMessage({ id: 'LandingPage.nold' })}{' '}
                        <span style={{ color: '#E2F79D' }}>
                          {intl.formatMessage({ id: 'LandingPage.byYou' })}
                        </span>
                      </div>
                    </Grid>
                    <Grid container item xs={12}>
                      <NamedLink name="NewListingPage" className={css.sellButton}>
                        {intl.formatMessage({ id: 'LandingPage.sell' })}
                      </NamedLink>
                    </Grid>
                  </Grid>

                  <Grid container item xs={12} md={3}>
                    <div className={css.howToSellStepWrapper}>
                      <Grid
                        container
                        item
                        xs={12}
                        justifyContent="flex-start"
                        alignContent="center"
                        alignItems="center"
                      >
                        <img src={photoIcon} className={css.icon} />
                        <div className={css.infoTextUp}>
                          {intl.formatMessage({ id: 'LandingPage.snap' })}
                          <br />
                          {intl.formatMessage({ id: 'LandingPage.upload' })}
                        </div>
                      </Grid>
                      <Grid container item xs={12}>
                        <div className={css.infoTextBottom}>
                          {intl.formatMessage({ id: 'LandingPage.info1' })}
                        </div>
                      </Grid>
                    </div>
                  </Grid>

                  <Grid container item xs={12} md={3}>
                    <div className={css.howToSellStepWrapper}>
                      <Grid container item xs={12}>
                        <img src={shareIcon} className={css.shareIcon} />
                        <div className={css.infoTextUp}>
                          {intl.formatMessage({ id: 'LandingPage.share' })}
                          <br />
                          {intl.formatMessage({ id: 'LandingPage.getOffers' })}
                        </div>
                        <div className={css.infoTextBottom}>
                          {intl.formatMessage({ id: 'LandingPage.info2' })}
                        </div>
                      </Grid>
                    </div>
                  </Grid>

                  <Grid container item xs={12} md={3}>
                    <div className={css.howToSellStepWrapper2}>
                      <Grid container item xs={12}>
                        <img src={piggiBank} className={css.piggyIcon} />
                        <div className={css.infoTextUp}>
                          {intl.formatMessage({ id: 'LandingPage.earn' })}
                          <br />
                          {intl.formatMessage({ id: 'LandingPage.immediately' })}
                        </div>
                        <div className={css.infoTextBottom}>
                          {intl.formatMessage({ id: 'LandingPage.info3' })}
                        </div>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </li>
            {/* <li className={css.section}>
                  <div className={css.sectionContentFirstChild}>
                    <SectionFilteredSearches />
                  </div>
                </li> */}
            {/* <li className={css.section}>
                  <div className={css.sectionContent}>
                    <SectionHowItWorks />
                  </div>
                </li> */}
            <li className={css.section}>
              {typeof window !== 'undefined' && <WeLoveSection intl={intl} />}
            </li>
            <li className={css.section}>
              <SectionWardrobeSpotlight />
            </li>

            <li className={css.section}>
              <div className={css.sectionContent}>
                <Grid container item xs={12}>
                  <Grid container item xs={12} md={3} alignContent="center" alignItems="center">
                    <Grid container item xs={12}>
                      <div className={css.howToSellText}>
                        {intl.formatMessage({ id: 'LandingPage.theNold' })}
                        <br /> {intl.formatMessage({ id: 'LandingPage.communityImpact' })}
                      </div>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item
                    xs={12}
                    md={3}
                    style={{
                      paddingLeft: '20px',
                    }}
                  >
                    <Grid
                      container
                      item
                      xs={12}
                      direction="column"
                      justifyContent="center"
                      alignContent="center"
                      alignItems="center"
                    >
                      <img src={gif1} className={css.gifIcon} />
                      <div className={css.infoTextUp}>
                        {marketplaceSavings.co2Saved}{' '}
                        {intl.formatMessage({ id: 'LandingPage.kgOfCO2' })}
                      </div>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      justifyContent="center"
                      alignContent="center"
                      alignItems="center"
                    >
                      <div className={css.infoTextBottomGif}>
                        {intl.formatMessage(
                          { id: 'LandingPage.co2Info' },
                          { treesSaved: marketplaceSavings.treesSaved }
                        )}
                        <br />
                        {intl.formatMessage({ id: 'LandingPage.co2InfoBottom' })}
                      </div>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item
                    xs={12}
                    md={3}
                    style={{
                      paddingLeft: '20px',
                    }}
                  >
                    <Grid
                      container
                      item
                      xs={12}
                      direction="column"
                      justifyContent="center"
                      alignContent="center"
                      alignItems="center"
                    >
                      <img src={gif3} className={css.gifIcon} />
                      <div className={css.infoTextUp}>
                        {intl.formatMessage(
                          { id: 'LandingPage.h2oSaved' },
                          { litersSaved: marketplaceSavings.litersSaved }
                        )}
                      </div>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      justifyContent="center"
                      alignContent="center"
                      alignItems="center"
                    >
                      <div className={css.infoTextBottomGif}>
                        {intl.formatMessage(
                          { id: 'LandingPage.h2oInfo' },
                          { peopleSaved: marketplaceSavings.peopleSaved }
                        )}
                        <br />
                        {intl.formatMessage({ id: 'LandingPage.h2oInfoBottom' })}
                      </div>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item
                    xs={12}
                    md={3}
                    style={{
                      paddingLeft: '20px',
                    }}
                  >
                    <Grid
                      container
                      item
                      xs={12}
                      direction="column"
                      justifyContent="center"
                      alignContent="center"
                      alignItems="center"
                    >
                      <img src={gif2} className={css.gifIcon} />
                      <div className={css.infoTextUp}>
                        {intl.formatMessage(
                          { id: 'LandingPage.wasteSaved' },
                          { wasteSaved: marketplaceSavings.wasteSaved }
                        )}
                      </div>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      justifyContent="center"
                      alignContent="center"
                      alignItems="center"
                    >
                      <div className={css.infoTextBottomGif}>
                        {intl.formatMessage(
                          { id: 'LandingPage.wasteInfo' },
                          { wasteSaved: Number(marketplaceSavings.wasteSaved * 0.7).toFixed(2) }
                        )}
                        <br />
                        {intl.formatMessage({ id: 'LandingPage.wasteSavedBottom' })}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </li>
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(withRouter, connect(mapStateToProps), injectIntl)(LandingPageComponent);

export default LandingPage;
