import React, { useState, useEffect } from 'react';
import css from './LandingPage.module.css';
import config from '../../config';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { util as sdkUtil } from '../../util/sdkLoader';
import { Grid } from '@mui/material';
import { FormattedMessage } from '../../util/reactIntl';
import AutoPlaySlideshow from './AutoPlaySlideshow';
import { get } from '../../util/api';
const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});
import { pushToPath } from '../../util/urlHelpers';
function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

function SectionWardrobeSpotlight() {
  const [spotlightUsers, setSpotlightUsers] = useState([]);

  useEffect(() => {
    get('/api/query-users')
      .then(resp => {
        const users = resp.length >= 18 ? resp.slice(0, 18) : resp;
        const screenWidth = typeof window !== 'undefined' && window.innerWidth;
        const maxNrOfListings = screenWidth && screenWidth < 768 ? 4 : 18;
        const listingsLeftToPush = maxNrOfListings - users.length;
        // let finalListings = [...users];
        if (users.length < 17) {
          for (let i = 1; i <= listingsLeftToPush; i++) {
            users.push(users[getRandomInt(users.length - 1)]);
          }
        }
        setSpotlightUsers(users);
      })
      .catch(e => console.log(e));
  }, []);

  if (spotlightUsers?.length === 0) {
    return null;
  }
  return (
    <div className={css.sectionContent}>
      <h2 className={css.sectionTitle}>
        <FormattedMessage id="SectionWardrobeSpotlight.title" />
      </h2>
      <Grid container item xs={12}>
        {spotlightUsers.map(u => {
          if (u && u?.id?.uuid) {
            const authorId = u.id.uuid;
            const gifImages = u.attributes.profile.publicData.gifImages;

            return (
              <Grid
                container
                item
                xs={6}
                sm={4}
                md={3}
                lg={2}
                onClick={() => {
                  pushToPath(`/u/${authorId}`);
                }}
              >
                {gifImages && gifImages.length > 0 ? (
                  <AutoPlaySlideshow gifImages={gifImages} user={u} />
                ) : (
                  <div
                    className={css.spotlightListingWrapper}
                    style={{
                      backgroundImage: `url(${l.images[0].attributes.variants['portrait-crop2x'].url})`,
                      backgroundSize: 'contain',
                      backgroundPosition: 'center',
                    }}
                  >
                    <div className={css.authorTag}>
                      {l.author.profileImage?.attributes && (
                        <img
                          className={css.authorPic}
                          src={l.author.profileImage.attributes.variants['portrait-crop'].url}
                        />
                      )}
                      @{l.author.attributes.profile.displayName?.replace(' ', '-')}
                    </div>
                  </div>
                )}
              </Grid>
            );
          } else {
            return null;
          }
        })}
      </Grid>
    </div>
  );
}

export default SectionWardrobeSpotlight;
